import React, {useRef, useEffect, useState} from 'react';
import init, { adjust_pixels, get_pixel_change_indexes } from "./wasm-lib/wasm_lib";

const lastFrames = [];
const changedPixels =[];

const NEON_GREEN = { r: 57, g: 255, b: 20 };

const maxChangesToIgnore = 200000;

const useWasm = true;

const getWasmChangedPixels = (newFrame, tenSecondsAgoFrame, threshold) => {
    return get_pixel_change_indexes(newFrame.data, tenSecondsAgoFrame.data, threshold, 200000);
}

const getChangedPixels = (newFrame, tenSecondsAgoFrame, threshold) => {
    if (useWasm) {
        return getWasmChangedPixels(newFrame, tenSecondsAgoFrame, threshold);
    }
    const cp = [];
    for (let i = 0; i < newFrame.data.length; i += 4) {
        if (Math.abs(newFrame.data[i] - tenSecondsAgoFrame.data[i]) > threshold || Math.abs(newFrame.data[i + 1] - tenSecondsAgoFrame.data[i + 1]) > threshold || Math.abs(newFrame.data[i + 2] - tenSecondsAgoFrame.data[i + 2]) > threshold) {
            cp.push(i);
            if (cp.length > maxChangesToIgnore+1) {
                console.log('Aborting diff, too many changes')
                break;
            }
        }
    }
    return cp;
}

const wasmApplyPixelChange = (newFrame, cp, alpha) => {
    return adjust_pixels(newFrame.data, cp, NEON_GREEN.r, NEON_GREEN.g, NEON_GREEN.b, alpha)
}

function applyChangedPixels(newFrame, cp, alpha) {
    if (useWasm) {
        return wasmApplyPixelChange(newFrame, cp, alpha);
    }

    for (const pixelIndex of cp) {
        let r = newFrame.data[pixelIndex];
        let g = newFrame.data[pixelIndex + 1];
        let b = newFrame.data[pixelIndex + 2];

        newFrame.data[pixelIndex] = lerpColor(NEON_GREEN.r, r, alpha);
        newFrame.data[pixelIndex + 1] = lerpColor(NEON_GREEN.g, g, alpha);
        newFrame.data[pixelIndex + 2] = lerpColor(NEON_GREEN.b, b, alpha)
    }
}

function drawFrame(ctx, canvas, video) {

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    let currentFrame = ctx.getImageData(0, 0, canvas.width, canvas.height);

    if (lastFrames.length >= 5) {
        let tenSecondsAgoFrame = lastFrames.shift();
        //let threshold = 50;
        const threshold = 50;

        const newFrame = new ImageData(new Uint8ClampedArray(currentFrame.data), currentFrame.width, currentFrame.height);

        // Iterate through pixels
        const cp = getChangedPixels(newFrame, tenSecondsAgoFrame, threshold);


        // Move the pixels to start
        changedPixels.unshift(cp);

        // Remove if we have to long history
        if (changedPixels.length > 30) {
            changedPixels.pop()
        }

        let alpha = 1;
        for (const cp of changedPixels) {
            // If there are too many changed pixels, fsck it
            if (cp.length > maxChangesToIgnore) {
                continue;
            }

            applyChangedPixels(newFrame, cp, alpha);

            if (alpha > 0.1) {
                alpha -= 0.05;
            }
        }
        ctx.putImageData(newFrame, 0, 0);
    }

    lastFrames.push(currentFrame);
}

function lerpColor(b, a, alpha) {
    return Math.round(a + (b-a) * alpha);
}


function VideoCanvas({videoRef, onSetOutputStream}) {
    const canvasRef = useRef(null);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', {willReadFrequently: true});
        let wasmIsLoaded = useWasm ? false : true;
        let lastRunTimestamp = 0;

        const lastDurations = [];

        const logDurations = (durations) => {
            let duration = 0;
            for (const d of durations) {
                duration += d;
            }
            console.log('Last 30 durations avg', duration / durations.length);
        }

        function drawToCanvas() {
            if (video.paused || video.ended) return;

            if (!canvas.width || !canvas.height  || !wasmIsLoaded) {
                // No point in drawing on a empty frame?
                requestAnimationFrame(drawToCanvas);
                return;
            }

            const now = Date.now();
            if (now - lastRunTimestamp < 250) {
                requestAnimationFrame(drawToCanvas);
                return;
            }
            lastRunTimestamp = now;

            drawFrame(ctx, canvas, video);

            const frameDrawTime = (Date.now() - lastRunTimestamp) / 1000;
            console.log('Frame draw time', frameDrawTime);
            lastDurations.unshift(frameDrawTime);

            // Remove if we have to long history
            if (lastDurations.length > 10) {
                lastDurations.pop()
            }

            logDurations(lastDurations);

            // Call this function again on next animation frame.
            requestAnimationFrame(drawToCanvas);
        }

        video.addEventListener('play', () => {
            drawToCanvas();
            const track = canvasRef.current.captureStream(4)
            console.log('Track is', track);
            onSetOutputStream(track);
        });

        video.addEventListener('loadedmetadata', () => {
            const videoDimensions = { width: video.videoWidth, height: video.videoHeight };
            console.log('Video dimensions', videoDimensions)
            setVideoDimensions(videoDimensions);

        });

        if (useWasm) {
            init().then(() => {
                wasmIsLoaded = true;
            })
        }


        // Optional: You might want to handle the cleanup.
        return () => {
            video.removeEventListener('play', drawToCanvas);
        };

    }, []);

    return (
        <div className='w-screen h-screen overflow-hidden relative'>
            <video ref={videoRef}
                playsInline
                muted
                autoPlay
                   style={{position: 'absolute', left: 0, top: 0, zIndex: -1}}
            />
            <canvas className="object-cover w-full h-full"
                    ref={canvasRef} width={videoDimensions.width} height={videoDimensions.height}></canvas>
        </div>
    );
}

export default VideoCanvas;