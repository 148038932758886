import React, {useEffect, useRef, useState} from "react";
import Peer from "simple-peer";
import {io} from "socket.io-client";

const { REACT_APP_BASE_URL } = process.env;


function HunterMode ({callId}) {
    const [callAccepted, setCallAccepted] = useState(false);
    const [callFailed, setCallFailed] = useState(false);
    const [me, setMe] = useState(null);
    const userVideo = useRef(null);
    const connectionRef = useRef(null);
    const socketRef = useRef();

    useEffect(() => {
        socketRef.current = io(REACT_APP_BASE_URL);
        socketRef.current.on("connect", (id) => {
            console.log('[Hunter] Socket connected');
        });

        console.log('Setting up "me" listener');
        socketRef.current.on("me", (id) => {
            console.log('[Hunter] Got socket message "me"', id);
            setMe(id);
        });

        return () => {
            console.log('[Hunter] Closing socket')
            socketRef.current.close()
        }
    }, [])

    // Call after we got a connection
    useEffect(() => {
        if (!me) {
            return;
        }

        const peer = new Peer({
            initiator: true,
            trickle: false,
            stream: null,
        });

        function logTrackQuality(track) {
            peer._pc.getStats( track )
                .then(statsReport => {
                console.log('Stats', statsReport);
                statsReport.forEach(report => {
                    if (report.type === 'inbound-rtp' || report.type === 'outbound-rtp') {
                        console.log("Track ID:", report.trackId);
                        console.log("Packets sent/received:", report.packetsSent || report.packetsReceived);
                        console.log("Bytes sent/received:", report.bytesSent || report.bytesReceived);
                        console.log("Packets lost:", report.packetsLost);
                        console.log("Jitter:", report.jitter);
                        console.log("Round trip time:", report.roundTripTime);
                        // Add any other metrics you're interested in.
                    }
                });
            });
        }


        peer.on("signal", (data) => {
            console.log('Peer sending signal', data);
            socketRef.current.emit("callUser", {
                userToCall: callId,
                signalData: data,
                from: me
            });
        });

        peer.on("stream", (stream) => {
            console.log('Got stream event', stream, stream.getTracks());
            if (userVideo.current) {
                console.log('Setting stream event');
                logTrackQuality(stream.getVideoTracks()[0]);
                setInterval(() => {
                    logTrackQuality();
                }, 1000)
                userVideo.current.srcObject = stream;
            }
        });

        socketRef.current.on("callAccepted", (signal) => {
            console.log('Got call accepted', signal);
            setCallAccepted(true);
            peer.signal(signal);
        });

        socketRef.current.on("recipientNotFound", (signal) => {
            setCallFailed(true);
            peer.signal(signal);
        });

        connectionRef.current = peer;
    }, [me]);


    return <>
        <div className="h-screen w-screen flex flex-col">
            <div className="flex-shrink-0 w-full h-4/5">
                {callAccepted && (
                    <video
                        id='video'
                        playsInline
                        ref={userVideo}
                        autoPlay
                        className="object-cover w-full h-full"
                        style={{backgroundColor: 'red'}}
                        muted
                    />
                )}
            </div>
            {(!callAccepted || callFailed) && <div className="fixed left-0 top-0 h-screen w-full flex items-center justify-center">
                <div className="inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        {!callAccepted && <div>Ringer opp {callId}..</div>}
                        {callFailed && <div>Fant ikkje blinken</div>}
                    </div>
                </div>
                <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>}
        </div>
        </>
}

export default HunterMode;