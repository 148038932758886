import React, { useEffect, useRef, useState } from "react";
import Peer from "simple-peer";
import { io } from "socket.io-client";
import './TargetMode.css';
import VideoCanvas from "./VideoCanvas";
import QRCode from "qrcode.react";

const { REACT_APP_BASE_URL } = process.env;

console.log('Socket url', REACT_APP_BASE_URL);

const enumerateDevices = () => {
  navigator.mediaDevices.enumerateDevices().then(devices => {
    console.log('Devices', devices);
  })
}

function TargetMode() {
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [outputStream, setOutputStream] = useState(null);
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState(null);

  const [callEnded, setCallEnded] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const myVideo = useRef(null);
  const socketRef = useRef();

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment',
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }, audio: true
    })
        .then((stream) => {
          enumerateDevices();
          console.log('Got stream from camera', stream, stream.getTracks())
          setStream(stream);
          if (myVideo.current) {
            console.log('Setting video element stream')
            myVideo.current.srcObject = stream;
          }
          else {
            console.log('My video not found')
          }
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
        });

    socketRef.current = io(REACT_APP_BASE_URL);
    socketRef.current.on("connect", (id) => {
      console.log('Socket connected');
    });

    console.log('Setting up "me" listener');
    socketRef.current.on("me", (id) => {
      console.log('Got socket message "me"', id);
      setMe(id);
    });

    console.log('Setting up "callUser" listener');
    socketRef.current.on("callUser", (data) => {
      console.log('[TARGET] Got callUser', data);;
      setReceivingCall(true);
      setCaller(data.from);
      setCallerSignal(data.signal);
    });

    return () => {
      console.log('Closing socket')
      socketRef.current.close()
    }

  }, []);

  useEffect(() => {
    if (receivingCall) {
      answerCall()
    }
  }, [receivingCall]);


  const answerCall = () => {
    setCallAccepted(true);

    //const peerStream  = outputStream;
    const peerStream  = outputStream;

    const config = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        },
        {
          urls: 'turn:turn.breim.no:3478',
          username: 'spelbreim',
          credential: 'spel'
        }
      ],
      // sdpSemantics: 'unified-plan'
    }

    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: peerStream,
      config
    });

    console.log('Answered call with stream', peerStream)

    peer.on('connect', () => {
      console.log('Woho, connected!');
    });

    peer.on("signal", (data) => {
      console.log('Sending signal', data);
      socketRef.current.emit("answerCall", { signal: data, to: caller });
    });

    peer.on("stream", (stream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = stream;
      }
    });

    socketRef.current.on("callUser", (data) => {
      console.log('Got call user?', data);
      peer.signal(data.signal);
    });

    peer.on("error", (err) => console.log("error", err));

    console.log('Answering call by signal', callerSignal);
    peer.signal(callerSignal);
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
  };

  return (<>
      <div className="h-screen w-full flex flex-col">
        <div className="flex-shrink-0 w-full h-4/5">
          <VideoCanvas videoRef={myVideo} onSetOutputStream={setOutputStream}/>
        </div>
        <div className="flex-grow bg-gray-200">
          <span className="text-white font-bold text-lg mb-4">{caller}</span>
          <p className="">{me}</p>
        </div>
    </div>
    {(callEnded || !callAccepted) && <div className="fixed left-0 top-0 h-screen w-full flex items-center justify-center">
      <div className="inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded shadow-lg w-96">
          <h1 className="text-xl font-bold mb-4">Scan the QR code</h1>
          <QRCode value={`${REACT_APP_BASE_URL}/?call=${me}`} className="mx-auto mb-4"/>
          <p className="text-gray-600">Scan the QR code to connect to video stream</p>
        </div>

      </div>
      <div className="absolute inset-0 bg-black opacity-50"></div>
    </div>
    }
  </>);
}

export default TargetMode;

