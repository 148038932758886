import './App.css';
import TargetMode from './TargetMode';
import {useEffect, useState} from "react";
import HunterMode from "./HunterMode";
import * as Sentry from "@sentry/react";
import {isDevMode} from "./utils";

if (!isDevMode()) {
    Sentry.init({
        dsn: "https://005b72e606319b74f68246c988cfb1e5@o378438.ingest.sentry.io/4505794943057920",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/jeger\.breim\.no/],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

function App() {
    const [type, setType] = useState('');
    const [callId, setCallId] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('call')) {
            setType('hunter');
            setCallId(params.get('call'));
        }
    }, []);

    if (type === 'target') {
        return <TargetMode />
    }

    if (type === 'hunter') {
        return <HunterMode callId={callId} />
    }

    return (
    <div className="App">
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-200 space-y-12">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => setType('target')}>Start target camera
            </button>
        </div>
    </div>
  );
}

export default App;
